import { useEffect, useState } from 'react';

const getCurrentWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export function useScreenSize() {
  const [size, setSize] = useState(() => {
    if (typeof window !== 'undefined') {
      return getCurrentWindowSize();
    }

    return {
      width: 0,
      height: 0,
    };
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSize(getCurrentWindowSize());

      const resizeCallback = (event: UIEvent) => {
        let targetWindow: Window = event.target as Window;

        setSize({
          width: targetWindow.innerWidth,
          height: targetWindow.innerHeight,
        });
      };

      window.addEventListener('resize', resizeCallback);

      return () => {
        window.removeEventListener('resize', resizeCallback);
      };
    }
  }, [typeof window]);

  return size;
}
