import * as SentryIntegrations from '@sentry/integrations';
// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import * as Sentry from '@sentry/node';

import { COMMIT_SHA, RELEASE_STAGE, SENTRY_DSN } from '../constants';
import { CurrentOrganisation } from '../contexts/application-context';
import type { CurrentUserType } from '../contexts/user-context';

export const initSentry = () => {
  const sentryOptions = {
    dsn: SENTRY_DSN,
    release: COMMIT_SHA,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    environment: RELEASE_STAGE,
  };

  // When we're developing locally
  if (process.env.NODE_ENV !== 'production') {
    // Don't actually send the errors to Sentry
    // @ts-ignore
    sentryOptions.beforeSend = () => null;

    // Instead, dump the errors to the console
    // @ts-ignore
    sentryOptions.integrations = [
      new SentryIntegrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ];
  }

  Sentry.init(sentryOptions);
};

initSentry();

export const captureException = (err, metadata?: any) => {
  console.log(err);

  // @ts-ignore
  Sentry.configureScope((scope) => {
    if (err.message) {
      // De-duplication currently doesn't work correctly for SSR / browser errors
      // so we force deduplication by error message if it is present
      scope.setFingerprint([err.message]);
    }

    if (err.statusCode) {
      scope.setExtra('statusCode', err.statusCode);
    }

    if (metadata) {
      try {
        Object.keys(metadata).forEach((key) => {
          scope.setExtra(key, metadata[key]);
        });
      } catch (err) {
        // do nothing
      }
    }

    try {
      scope.setExtra('error.serialized', JSON.stringify(err, null, '\t'));
    } catch (err) {
      // do nothing
    }
  });

  return Sentry.captureException(err);
};

export function setSentryUser(user: CurrentUserType) {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: user._id,
      email: user.email,
      username: user.name,
    });
  });
}

export function setSentryOrganisation(org: CurrentOrganisation) {
  Sentry.configureScope((scope) => {
    scope.setExtra('organisation.name', org.name);
    scope.setExtra('organisation._id', org._id);
    scope.setExtra('organisation.id', org.id);
  });
}
