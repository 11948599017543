import urlJoin from 'url-join';

export const RELEASE_STAGE = process.env.STAGE || 'development';
const getApiRoot = () => {
  switch (RELEASE_STAGE) {
    case 'production':
      return 'https://api.viresmo.com';
    case 'staging':
      return 'https://staging-api.viresmo.com';
    default:
      return 'http://localhost:4000';
  }
};
export const COMMIT_SHA = process.env.VERCEL_GITHUB_COMMIT_SHA;
export const BUILD_ID = process.env.NEXT_BUILD_ID;
export const API_ROOT = getApiRoot();
export const GRAPHQL_ENDPOINT = urlJoin(API_ROOT, '/graphql');

export const REFRESH_POLL_INTERVAL = 30000;
export const UPLOAD_SIZE_LIMIT = 25;
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SUPPORT_EMAIL = 'support@viresmo.com';
