import { authExchange } from '@urql/exchange-auth';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { retryExchange } from '@urql/exchange-retry';
import { GRAPHQL_ENDPOINT } from '~/constants';
import unfetch from 'isomorphic-unfetch';
import { cacheExchange, createClient, errorExchange, fetchExchange } from 'urql';

import { getAccessToken, isTokenValid } from '../auth/client';
import { captureException } from '../sentry';

const RETRY_OPTIONS = {
  initialDelayMs: 500,
  maxDelayMs: 2500,
  randomDelay: true,
  maxNumberAttempts: 3,
  retryIf: (err) => {
    if (!err) return false;
    return Boolean(err.networkError || err.message.includes('server is stopping'));
  },
};

export function createGraphqlClient(opts: { organisationId: string }) {
  let { organisationId } = opts;

  return createClient({
    url: GRAPHQL_ENDPOINT,
    fetch: unfetch,
    exchanges: [
      requestPolicyExchange({
        // Upgrade cache-first and cache-only to cache-and-network after given ttl in ms
        ttl: 5 * 60_000,
      }),
      cacheExchange,
      authExchange(async (utils) => {
        let authState = await getAccessToken({
          forceRefresh: false,
        });

        return {
          addAuthToOperation(operation) {
            if (authState.token) {
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${authState.token}`,
                organisation: organisationId,
              });
            }
            return operation;
          },
          willAuthError(_operation) {
            // e.g. check for expiration, existence of auth etc
            return !authState.token || !isTokenValid(authState.decoded);
          },
          didAuthError(error, _operation) {
            return error.graphQLErrors.some((e) => e.extensions?.code === 'UNAUTHENTICATED');
          },
          async refreshAuth() {
            authState = await getAccessToken({
              forceRefresh: true,
            });
          },
        };
      }),
      retryExchange(RETRY_OPTIONS),
      fetchExchange,
      errorExchange({
        onError: (error) => {
          captureException(error);
        },
      }),
    ],
  });
}
