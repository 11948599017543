import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export type DateLike = dayjs.ConfigType;
export type TimeObject = {
  hour: number;
  minute: number;
};

export function setDateLocale(locale: string = 'nl') {
  dayjs.locale(locale);
}

export function formatDate(date: DateLike, format: string = 'DD/MM/YYYY') {
  return dayjs(date).format(format);
}

export function formatDateTime(date: DateLike): string {
  return formatDate(date, 'ddd L LT');
}

export function formatTime(date: DateLike, interval: number = 1) {
  let formatted = dayjs(date).format('HH:mm');
  if (interval > 1) {
    let parsed = parseTime(formatted);
    return formatTimeFromTimeObject({
      hour: parsed.hour,
      minute: Math.ceil(parsed.minute / interval) * interval,
    });
  }
  return formatted;
}

export function formatDateForInput(date: DateLike) {
  return formatDate(date, 'YYYY-MM-DD');
}

export function timeDifference(date: DateLike, reference: DateLike): string {
  return dayjs(date).from(reference);
}

export function parseTime(time: string): TimeObject {
  let timeSplit = time.split(':').map((i) => parseInt(i, 10));

  if (timeSplit.length !== 2) {
    return {
      hour: timeSplit[0] ? timeSplit[0] : 0,
      minute: timeSplit[1] ? timeSplit[1] : 0,
    };
  }

  return {
    hour: timeSplit[0],
    minute: timeSplit[1],
  };
}

export function formatTimeFromTimeObject(time: TimeObject): string {
  return `${time.hour.toString(10).padStart(2, '0')}:${time.minute.toString(10).padStart(2, '0')}`;
}

export function datetimeToUTC(date: DateLike, time: string) {
  let parsedTime = parseTime(time);
  let dateTime = dayjs(date);
  dateTime = dateTime.set('hour', parsedTime.hour).set('minute', parsedTime.minute);
  return dateTime.utc().format();
}

export function millisDifference(date: DateLike, reference: DateLike): number {
  return dayjs(date).diff(reference, 'ms');
}
