export const TRANSLATIONS = {
  actions: 'Actions',
  address: 'Address',
  adminSettings: 'Admin settings',
  allergenEnum: {
    celery: 'Celery',
    crustaceans: 'Crustaceans',
    eggs: 'Eggs',
    fish: 'Fish',
    gluten: 'Gluten',
    lupin: 'Lupin',
    milk: 'Milk',
    molluscs: 'Molluscs',
    mustard: 'Mustard',
    nuts: 'Nuts',
    peanuts: 'Peanuts',
    sesame: 'Sesame',
    soya: 'Soya',
    sulphurDioxide: 'Sulphur Dioxide',
  },
  allergens: 'Allergens',
  amountOfBoxes: 'Amount of boxes',
  'api-keys': 'API Keys',
  apiKeysPage: {
    apiKeyCreated: 'Api key has been created',
    apiKeyDeleted: 'Api key has been removed',
    copyToken: 'Copy Token',
    create: 'Created API Key',
    tokenCopied: 'Token copied',
    tokenHasBeenCopied: 'Token has been copied and can be pasted into the third party application',
  },
  areYouSure: 'Are you sure?',
  authentication: 'Authentication',
  authenticationType: 'Authentication Type',
  automaticallySendNotification: 'Send notification automatically',
  available: 'Available',
  box: 'Box',
  boxes: 'Boxes',
  brandColor: 'Brand Color',
  brandingSettings: 'Branding settings',
  cancel: 'Cancel',
  canUseMealVouchers: 'Can use meal vouchers?',
  changePasswordPage: {
    confirmPassword: 'Confirm Password',
    disclaimer:
      'For improved security, we highly recommend you to use the email login or SSO instead of configuring a password.',
    enablePasswordLogin: 'Enable password login',
    password: 'Password',
    passwordsNotEqual: 'Password and confirm password should be the same',
    passwordTooShort: 'Password is too short',
    title: 'Change Password',
  },
  city: 'City',
  close: 'Close',
  connectionStatus: 'Connection status',
  contactDetails: 'Contact Details',
  content: 'Content',
  countries: {
    be: 'Belgium',
    fr: 'France',
    nl: 'Netherlands',
  },
  country: 'Country',
  createdAt: 'Created at',
  currentStatus: 'Current status',
  customer: 'Customer',
  customers: 'Customers',
  customersPage: {
    couldNotDelete: 'Could not remove customer',
    create: 'Create a customer',
    customerCreated: 'Customer has been created successfully',
    customerRemoved: 'Customer has been removed',
    customerUpdated: 'Customer updates successfully',
    invalidPincode: 'Pincode should be 5 numeric characters',
    noContactInformation: 'No contact details',
    noContactInformationDescription:
      "you have not provided any contact details for customer {firstName}, because of this we won't be able to send any notifications.",
    notFound: 'Customer not found',
    searchCustomer: 'Search for a customer…',
  },
  date: {
    lastMonth: 'Last month',
    lastWeek: 'Last week',
    nextMonth: 'Coming month',
    nextWeek: 'Coming week',
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
  },
  delete: 'Delete',
  description: 'Description',
  details: 'Details',
  deviceId: 'Device ID',
  deviceLogs: {
    title: 'Device Logs',
  },
  discount: 'Discount',
  discountPercentage: 'Discount percentage',
  documentation: 'Documentation',
  edit: 'Edit',
  email: 'Email',
  emailNotification: 'Email notification',
  errors: {
    serverError: 'A server error occurred',
  },
  export: 'Export',
  externalId: 'External ID',
  extraInformation: 'Extra information',
  firmwareVersion: 'Firmware version',
  firstName: 'First Name',
  hasNutritionalInformation: 'Contains nutritional information',
  id: 'ID',
  image: 'Image',
  import: 'Import',
  includedProducts: 'Included products',
  interval: 'Interval',
  isBetaTester: 'Receives Beta Firmware?',
  isInActive: 'Is inactive',
  isVendingReservation: 'Is for Woyti?',
  isVirtualVendingEnabled: 'Enable Woyti',
  language: 'Language',
  languageEnum: {
    de: 'German',
    en: 'English',
    fr: 'French',
    nl: 'Dutch',
  },
  lastErrorAt: 'Most recent error',
  lastErrorMessage: 'Most recent error message',
  lastName: 'Last Name',
  lastSeenAt: 'Last seen',
  lastStateUpdate: 'Last status update',
  linkedReservations: 'Linked reservations',
  locker: 'Locker',
  lockers: 'Lockers',
  lockersPage: {
    boxOpened: 'Box has been opened',
    connectALocker: 'Connect a locker',
    couldNotDelete: 'Could not remove locker',
    couldNotOpenBox: 'Box could not be opened',
    created: 'Locker has been created',
    disableLockdown: 'Disable lockdown',
    disableLockdownConfirm:
      'Are you sure you want to disable lockdown for this locker? This is usually due to a technical error with temperature, please ensure that any food stored at the wrong temperature is disposed of.',
    enableLockdown: 'Enable lockdown',
    enableLockdownConfirm:
      'Are you sure you want to enable lockdown for this locker? Customers will not be able to pick up their orders as long as this is enabled.',
    lockdownMessage:
      'The box was blocked due to temperature problems. Find out what the cause is and check the Quality of the products in the box. Click on ‘enable lockdown’ at lockers to allow collections again.',
    hasBeenModified: 'locker has been changed',
    inTheVersbox: 'In the versbox',
    lockdownStatusUpdated: 'Lockdown status has been updated',
    notFound: 'Locker not found',
    openAll: 'Open all boxes',
    openMainDoor: 'Open main door',
    settings: 'Locker Settings',
    viewAllReservations: 'View all reservations',
    viewReservation: 'View reservation',
  },
  lockerTemperatureType: {
    NotCooled: 'Not Cooled',
    Refrigerated: 'Refrigerated',
    RoomTemperature: 'Room Temperature',
    Heated: 'Heated',
  },
  lockStatusses: {
    closed: 'Closed',
    open: 'Open',
    opened: 'Opened',
  },
  log: 'Log',
  logo: 'Logo',
  LogOperationEnum: {
    Created: 'Created',
    Deleted: 'Deleted',
    Error: 'Error',
    Opened: 'Opened',
    Received: 'Received',
    Sent: 'Sent',
    Updated: 'Changed',
  },
  logs: 'Logs',
  logsPage: {
    notFound: 'Log not found',
    operationFilter: 'Filter on operation',
    severityFilter: 'Filter on severity',
    typeFilter: 'Filter on type',
  },
  mainDoor: 'Main Door',
  message: 'Message',
  minimumAmount: 'Minimal amount',
  name: 'Name',
  newNotifications: "You've got new notifications",
  next: 'Next',
  noAutoComplete: "Fixed reservation (don't autocomplete)",
  noBoxReserved: "No box reserved, customer's pincode cannot be used unless a box has been reserved",
  notification: 'Notification',
  notifications: 'Notifications',
  number: 'Number',
  nutritionalInformation: 'Nutritional information',
  nutritionalInformationItems: {
    addedSugars: 'Added Sugars',
    carbohydrate: 'Carbohydrates',
    cholesterol: 'Cholesterol',
    energy: 'Energyvalue (in kCal)',
    fat: 'Fat',
    fiber: 'Fiber',
    protein: 'Protein',
    salt: 'Salt',
    saturatedFat: 'Saturated Fat',
    sugars: 'Sugars',
  },
  operation: 'Operation',
  order: 'Order',
  orderAmount: 'Total order amount',
  orderConfirmationEmailTemplate: 'Order confirmation email',
  orderLines: 'Ordered articles',
  orderNumber: 'Order number',
  orders: 'Orders',
  ordersPage: {
    confirmationSent: 'Order confirmation sent',
    create: 'Create order',
    notFound: 'Order not found',
    orderCanceled: 'Order has been cancelled',
    orderChanged: 'Order successfully changed',
    orderStatus: 'Order Status',
    searchOrder: 'Find an order…',
  },
  refund: {
    title: 'Refund',
    minimumRefundError: 'Should refund at least 1 cent',
    maximumRefundError: 'Cannot refund more than the order total',
    amount: 'Refund amount',
    description: 'Refund description',
    descriptionPlaceholder: 'Return of goods #125',
    confirm: 'Confirm refund',
    success: 'Refund successfully sent',
    failed: 'Could not refund order',
  },
  orderStatusEnum: {
    Canceled: 'Canceled',
    PaymentCanceled: 'Payment Canceled',
    Created: 'Created',
    Expired: 'Expired',
    PaymentExpired: 'Payment Expired',
    Paid: 'Paid',
    Refunded: 'Refunded',
  },
  organisation: 'Organisation',
  organisationScreen: {
    companyName: 'Company Name',
    couldNotRemove: 'Could not remove organisation',
    create: 'Create Organisation',
    created: 'Organisation has been created',
    registrationNumber: 'Company Number',
    registrationNumberRequired: 'Company number is required',
    search: 'Search Organisation',
    selectionHeading: 'Organisations',
  },
  origin: 'Origin',
  overviewScreen: {
    inLocker: 'Locker',
    inQueue: 'Queue',
    someDevicesAreOffline:
      'Devices "{devices}" are offline, this may result in some reservations not being synchronized with the device.',
    title: 'Overview',
    hiddenTag: 'Hidden',
    vendingTag: 'Woyti',
    lockersInLockdown:
      'Lockers "{lockers}" are in lockdown, this is likely due to a temperature problem, go to the lockers page for more details.',
    woytiOrdersToNotify: 'Still need to notify customers about {amount} new woyti reservations',
  },
  overwriteCodeScreen: {
    changedSuccess: 'Successfully changed overwrite code',
    confirmRemoval: 'Are you sure you want to remove this overwrite code?',
    create: 'Create overwrite code',
    created: 'Overwrite code has been created',
    editTitle: 'Edit overwrite code',
    invalidPincode: 'Pincode should be 6 numeric characters',
    notFound: 'Overwrite code not found',
    overviewTitle: 'Overwrite Codes',
    removedSuccess: 'Overwrite code {name} has been removed',
    search: 'Find overwrite code...',
  },
  pageHeader: {
    organisationSelection: 'Organisation selection',
    shopSelection: 'Shop selection',
  },
  paidAt: 'Paid at',
  password: 'Password',
  passwordValidation: {
    length: 'Minimal 8 characters',
    lowercase: 'Contains small letters',
    numbers: 'Contains numbers',
    uppercase: 'Contains capital letters',
  },
  phonenumber: 'Phone number',
  pickupCode: 'Pickup code',
  pickupDate: 'Pickup date',
  pickupTime: 'Pickup time',
  pincode: 'PIN Code',
  previous: 'Previous',
  price: 'Price',
  pricePerType: 'Price per {type}',
  product: 'Product',
  products: 'Products',
  productsPage: {
    changed: 'Product has been successfully changed',
    changeFailed: 'Product could not be changed',
    create: 'Create product',
    created: 'Product has been created',
    creationFailed: 'Product could not be created',
    deleteFailed: 'Product could not be removed',
    notFound: 'Product not found',
    removed: 'Product has been successfully removed',
    search: 'Find a product…',
    deleteAll: 'Delete all',
    productsDeleted: 'Deleted products',
  },
  provideOwnPincode: "Don't automatically generate pincode",
  quotaDetails: {
    title: 'Quota Details',
    usageLog: 'Usage Log',
  },
  quotas: {
    lastResetAt: 'Last Reset At',
    resetsAt: 'Resets at',
    title: 'Quotas',
    type: 'Quota Type',
    usage: 'Quota usage',
  },
  rawData: 'Raw Data',
  refresh: 'Refresh',
  refunded: 'Refunded',
  relatedDocuments: 'Related documents',
  removeAt: 'Automatically removed at',
  reservation: 'Reservation',
  reservationActions: {
    boxesRequired: 'All boxes should have a selection',
    cancellationWarning:
      "Are you sure you want to cancel the reservation? This cannot be undone, please don't forget to empty the box.",
    completeReservation: 'Complete reservation',
    completeReservationConfirmation: 'Are you sure you want to complete the reservation?',
    insufficientBoxes: 'Not enough available boxes',
    orderAmountShouldBeLargerThanZero: 'Order amount should be larger than zero',
    reserveBox: 'Reserve box',
    reserveBoxesForReservation: 'Select box(es) for reservation',
    selectABox: 'Select a box',
    sendNotification: 'Send notification',
    sendNotificationConfirmation: 'Are you sure you want to send a notification?',
    shouldSelectALocker: 'Should select a locker',
    showRecentlyOpenedBoxes: 'Show recently opened boxes?',
    toCreated: 'Remove reservation from locker',
    toCreatedConfirmation: 'Are you sure you want to remove reservation from locker?',
  },
  reservationReadyEmailTemplate: 'Reservation ready email template',
  reservationReadySMSTemplate: 'Reservation ready sms template',
  reservations: 'Reservations',
  reservationScreen: {
    confirmUpdateSentCompleted:
      'Are you sure you want to update a reservation with an already sent notification? This should only be used to fix typos in contact details.',
    create: 'Create reservation',
    createWoyti: 'Create Woyti reservation',
    createReservation: 'Create reservation',
    editReservation: 'Edit reservation',
    woytiNotificationDispatched: 'Woyti notification dispatched',
    woytiNotificationDispatchingFailed: 'Woyti notification dispatching failed',
    dispatchWoytiNotification: 'Send woyti notification',
    formTabs: {
      contactDetails: 'Contact Details',
      extraOptions: 'Extra options',
      general: 'General',
      vending: 'Woyti',
    },
    hasAlreadyPaid: 'Has already paid',
    invalidPincode: 'Pin code should be 4 numeric characters',
    noNotificationTypesSelected:
      "Because you haven't selected any notification type, your customer will not receive any notification.",
    noNotificationTypesSelectedTitle: 'No notification types selected',
    notFound: 'No reservations found',
    notFoundListDescription: 'No reservations found, please update filters',
    notificationDispatchFailed: 'Could not send notification',
    notificationSent: 'Notification sent',
    reservationCreated: 'Reservation has been created',
    reservationHasChanged: 'Reservation has changed, click on refresh to display the updated values.',
    reservationInformation: 'Reservation details',
    reservationModified: 'Reservation has been successfully modified',
    reservationStatusFilter: 'Reservation status',
    search: 'Find a reservation…',
    sendNotificationAt: 'Send notification at',
    shouldCustomerPay: 'Should the customer still pay?',
    shouldPay: 'Should pay',
    statusChanged: 'Status has been changed successfully',
    statusChangeFailed: 'Status could not be changed',
    viewLocker: 'View Locker',
  },
  reservationSettingsScreen: {
    title: 'Reservation Settings',
    shouldCustomerPay: 'Should Customer Pay',
    selectAvailableBoxByDefault: 'Select available box by default',
    changed: 'Reservation settings successfully updated',
  },
  reservationStatusEnum: {
    cancelled: 'Cancelled',
    completed: 'Completed',
    created: 'Created',
    ordered: 'Ordered',
    reserved: 'Reserved',
    sent: 'Notification sent',
  },
  role: 'Role',
  save: 'Save',
  select: 'Select',
  selectAnOrganisation: 'Select an organisation',
  sendFeedback: 'Feedback',
  serverNotifications: {
    noNotifications: 'No notifications right now',
  },
  'service-location': 'Gateway',
  'service-locations': 'Gateways',
  serviceLocationScreen: {
    lastOfflineAt: 'Last Offline',
  },
  serviceLocationsPage: {
    changed: 'Gateway changed',
    changeFailed: 'Could not change gateway',
    create: 'Create gateway',
    created: 'Gateway successfully created',
    creationFailed: 'Gateway could not be created',
    notFound: 'Gateway not found',
    search: 'Find gateway...',
  },
  settings: {
    changed: 'Settings have been changed',
    generalSettings: 'General settings',
    email: {
      settings: 'Email settings',
      validation: {
        domainRequiresSMTP: 'You need to fill in an SMTP-server for all domains other than %s',
      },
      customSMTPWarning:
        'Please note that we cannot guarantee e-mail delivery when using a custom SMTP server, even if the initial connection is validated. Please make sure the provided SMTP server is configured to send e-mails using the provided settings.',
      useCustomSettings: 'Use custom email settings',
      fromEmail: "From mail address ('From: ')",
      smtpServer: 'SMTP server',
      smtpPort: 'SMTP port',
    },
    payment: 'Payment Settings',
    paymentScreen: {
      connectMollie: 'Connect with Mollie',
      connectMollieAgain: 'Connect again',
      disableTestmode: 'Disable testmode',
      disableTestmodeConfirm: 'Are you sure you want to disable test mode?',
      disconnectMollie: 'Disconnect Mollie',
      disconnectMollieConfirm:
        "Are you sure you want to disconnect Mollie, after doing this you won't be able to accept any more payments?",
      enableTestmode: 'Enable testmode',
      enableTestmodeConfirm:
        'Are you sure you want to enable test mode? This will allow customers to order without paying.',
      gotoMollieDashboard: 'Go to Mollie dashboard',
      mollieLinked: 'Mollie has been successfully linked',
      mollieOrganization: 'Mollie Organization',
      mollieOrganizationName: 'Linked Mollie organisation',
      mollieProfile: 'Mollie Profile',
      stillProcessing: 'Still processing information… Please go to the Mollie dashboard for details',
      verificationStatus: 'Verification status',
      verified: 'Verified, can receive payments',
    },
    virtualVending: 'Woyti settings',
    virtualVendingScreen: {
      findVirtualVendingAt: 'You can find your woyti machine at',
      favoriteCount: 'Woyti favorite count: {count}',
    },
  },
  settingsPage: {
    changed: 'Settings have been changed',
    updateFailed: 'Could not change settings',
  },
  severityEnum: {
    danger: 'Danger',
    info: 'Info',
    success: 'Success',
    warning: 'Warning',
  },
  shop: 'Shop',
  shopScreen: {
    create: 'Create Shop',
    createdSuccessfully: 'Shop has been created',
    heading: 'Shops',
    hideVirtualVendingInOverview: 'Hide Woyti in overview',
    invalidPickupTime: 'Pickup time is invalid',
    invalidTimeBeforeDispatch: 'Time before confirmation is invalid',
    organisationSettings: 'Organisation Settings',
    overview: 'Shops Overview',
    search: 'Search shops...',
    timeBeforeConfirmationDispatch: 'Time before confirmation notification',
  },
  sidebar: {
    hide: 'Hide Sidebar',
  },
  smsNotification: 'SMS notification',
  state: 'Province',
  status: 'Status',
  street: 'Street',
  subject: 'Subject',
  temperature: 'Temperature',
  testDevice: 'Test Device',
  time: 'Time',
  total: 'Total',
  type: 'Type',
  undo: 'Undo',
  unitEnum: {
    liter: 'Liter',
    kg: 'Kilogram',
    unit: 'Unit',
  },
  unitAmount: {
    liter: 'Centiliter',
    kg: 'Gram',
    unit: 'Unit',
  },
  disclaimer: {
    usingAgreeToTerms: 'By using this application you agree to our terms and conditions',
  },
  unitType: 'Unit',
  unknown: 'Unknown',
  user: 'User',
  username: 'Username',
  userDropdown: {
    changePassword: 'Change Password',
    logout: 'Log out',
    preferences: 'User Preferences',
  },
  userPreferences: {
    title: 'User Preferences',
    updatedSuccess: 'User Preferences have been updated successfully',
  },
  usersPage: {
    deleteConfirmation: 'Are you sure you want to remove this user?',
    invite: 'Invite a new user',
    inviteSendingFailed: 'Could not send invitation',
    inviteSent: 'Invitiation sent',
    pincodeUpdated: 'PIN Code succesfully changed',
    pincodeUpdateFailed: 'Could not update pin code',
    sendInvitation: 'Send invitation',
    usersTitle: 'Users',
  },
  validation: {
    addressRequired: 'Address is required',
    amountOfBoxes: 'Amount of boxes should be larger or equal to 1',
    boxCount: 'Amount of boxes is required',
    contentRequired: 'Content is required',
    descriptionRequired: 'Description is required',
    emailRequired: 'Email is required',
    firstNameRequired: 'First name is required',
    intervalTooSmall: 'Interval should be larger or equal to 1',
    invalidEmail: 'Email is invalid',
    invalidName: 'Name is invalid',
    invalidPhonenumber: 'Phonenumber is invalid',
    language: 'Language is required',
    lastNameRequired: 'Last name is required',
    locker: 'Locker is required',
    minimumAmountTooSmall: 'Minimal amount should be larger or equal to 1',
    nameRequired: 'Name is required',
    phonenumber: 'Phone number is invalid',
    pickupInFuture: 'Pickup time should be in the future',
    pickupTimeRequired: 'Pickup time is required',
    priceTooLow: 'Price should be at least 0',
    role: 'Role is required',
    serviceLocation: 'Gateway is required',
    tinInvalid: 'TIN number is invalid',
    tinRequired: 'TIN number is required',
    type: 'Type is required',
    vendingProducts: 'Woyti reservation should contain at least one product',
    fieldIsRequired: 'Field may not be empty',
  },
  vatNumber: 'TIN Number',
  vatRate: 'TAX Rate',
  vending: 'Woyti',
  vendingPickupTime: 'Time between order and pickup',
  vendingProducts: 'Products in Woyti reservation',
  viewAll: 'View All',
  virtualVendingMachine: 'Woyti',
  zipcode: 'ZIP Code',
  confirm: 'Confirm',
  webshopUrl: 'Webshop url',
  integrations: {
    title: 'Integrations',
    integration: 'Integration',
  },
  temperatureLogs: {
    title: 'Temperature logs',
  },
  registeredAt: 'Registered at',
  autoHideAfterXHours: 'Auto hide after x hours',
  hours: 'Hours',
};
