import fetch from 'isomorphic-unfetch';
import jwtDecode from 'jwt-decode';
import urlJoin from 'url-join';

// 5 minutes
const JWT_REFRESH_MARGIN = 300;

interface GetAccessTokenOpts {
  forceRefresh?: boolean;
  shouldRedirect?: boolean;
}

let cachedTokenData: { decoded?: any; token?: string } = {};
export function isTokenValid(decoded: any) {
  if (!decoded) {
    return false;
  }

  try {
    let currSecs = Math.round(Date.now() / 1000 + JWT_REFRESH_MARGIN);
    return decoded.exp > currSecs;
  } catch (err) {
    // do nothing...
  }

  return false;
}

function redirectToLogin() {
  window.location.href = `/auth/login?redirectTo=${window.location.pathname}`;
}

export function logout() {
  window.location.href = '/api/auth/logout';
}

let fetchPromise = null;
export async function getAccessToken(opts: GetAccessTokenOpts = {}) {
  let { forceRefresh = false, shouldRedirect = true } = opts;
  if (!forceRefresh && cachedTokenData.token && cachedTokenData.decoded && isTokenValid(cachedTokenData.decoded)) {
    return cachedTokenData;
  }

  try {
    if (!fetchPromise) {
      fetchPromise = fetch(urlJoin(`/api/auth/token${forceRefresh ? '?refresh=true' : ''}`), {
        credentials: 'same-origin',
      });
    }

    let res: any = await fetchPromise;
    if (!res.ok) {
      throw new Error('No valid response.');
    }

    res = await res.json();
    let accessToken = res?.accessToken;
    if (typeof accessToken === 'string') {
      cachedTokenData = {
        token: accessToken,
        decoded: jwtDecode(accessToken),
      };

      fetchPromise = null;

      return cachedTokenData;
    }

    throw new Error('No accessToken in /api/auth/token response.');
  } catch (err) {
    if (shouldRedirect) {
      redirectToLogin();
    }
    fetchPromise = null;
    return null;
  }
}
