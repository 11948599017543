export const AuthRoles = {
  1000: 'Member',
  5000: 'Admin',
  7000: 'Dealer',
  8000: 'Reseller',
  9999: 'SuperUser',
};

export const AuthRolesEnum = {
  Member: 1000,
  Admin: 5000,
  Dealer: 7000,
  Reseller: 8000,
  SuperUser: 9999,
};

export const RESERVATION_STATUS_MAP = {
  cancelled: 0,
  created: 50,
  reserved: 100,
  sent: 150,
  completed: 200,
};
