import { captureException } from '~/utils/sentry';
import React from 'react';

import { ErrorFallback } from './error';

interface Props {}

interface State {
  hasError: boolean;
}

export class GlobalErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return {
      hasError: true,
    };
  }

  // @ts-ignore
  componentDidCatch(error, errorInfo) {
    captureException(error, { errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Render error fallback
      return <ErrorFallback />;
    } else {
      // Render the normal page
      return this.props.children;
    }
  }
}
