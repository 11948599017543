import { useCallback, useEffect, useReducer } from 'react';

const blacklistedTargets = ['input', 'textarea'];

const keysReducer = (state, action) => {
  switch (action.type) {
    case 'set-key-down':
      return { ...state, [action.key]: true };
    case 'set-key-up':
      return { ...state, [action.key]: false };
    default:
      return state;
  }
};

// Inspired by https://github.com/jamiebuilds/tinykeys
// Ensure and stop any event that isn't a full keyboard event.
// Autocomplete option navigation and selection would fire a instanceof Event,
// instead of the expected KeyboardEvent
const isValidKeyboardEvent = (event: any) => {
  if (!event) {
    return false;
  }

  if (!(event instanceof KeyboardEvent)) {
    return false;
  }

  if (!event.key) {
    return false;
  }

  return true;
};

export default function useKeybind(shortcutKeys: Array<String>, callback: (keys: Array<string>) => any) {
  const initialKeyMapping = shortcutKeys.reduce((currentKeys, key) => {
    return {
      ...currentKeys,
      [key.toLowerCase()]: false,
    };
  }, {});

  const [keys, setKeys] = useReducer(keysReducer, initialKeyMapping);

  const keydownListener = useCallback(
    (keydownEvent) => {
      if (!isValidKeyboardEvent(keydownEvent) || keydownEvent.repeat) {
        return;
      }

      if (blacklistedTargets.includes(keydownEvent.target.tagName.toLowerCase())) {
        return;
      }

      const loweredKey = keydownEvent.key.toLowerCase();
      if (keys[loweredKey] === undefined) {
        return;
      }

      if (keys[loweredKey] === false) {
        setKeys({ type: 'set-key-down', key: loweredKey });
      }
    },
    [keys]
  );

  const keyupListener = useCallback(
    (keyupEvent) => {
      if (!isValidKeyboardEvent(keyupEvent)) {
        return;
      }

      const loweredKey = keyupEvent.key.toLowerCase();

      if (blacklistedTargets.includes(keyupEvent.target.tagName.toUpperCase())) {
        return;
      }

      if (keys[loweredKey] === undefined) {
        return;
      }

      if (keys[loweredKey] === true) {
        setKeys({ type: 'set-key-up', key: loweredKey });
      }
    },
    [keys]
  );

  useEffect(() => {
    if (!Object.values(keys).filter((value) => !value).length) {
      callback(keys);
    }
  }, [callback, keys]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', keydownListener, true);

      return () => {
        window.removeEventListener('keydown', keydownListener, true);
      };
    }
  }, [keydownListener, typeof window]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keyup', keyupListener, true);

      return () => {
        window.removeEventListener('keyup', keyupListener, true);
      };
    }
  }, [keyupListener, typeof window]);
}
