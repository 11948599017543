import React from 'react';

export default function useLocalstorage(key: string, defaultValue: any = '') {
  const [value, setValue] = React.useState(defaultValue);
  const hasLocalStorage = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';

  React.useEffect(() => {
    if (hasLocalStorage) {
      try {
        setValue(JSON.parse(window.localStorage.getItem(key)));
      } catch (err) {
        console.error(err);
      }
    }
  }, [hasLocalStorage]);

  const updateValue = (value: any) => {
    if (hasLocalStorage) {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (err) {
        console.error(err);
      }
    }

    setValue(value);
  };

  return [value, updateValue];
}
