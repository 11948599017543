import * as React from 'react';
import { v1 as uuid } from 'uuid';

import createContext from './create-context';

type NotificationType = 'error' | 'success' | 'warning' | string;

type ProviderProps = {
  children: React.ReactNode;
};

type NotificationObjectType = {
  id: string;
  type: NotificationType;
  title: React.ReactNode;
  content?: React.ReactNode;
  persistent: boolean;
  timeout: number;
  createdAt: Date;
};

type NotificationEmitData = {
  title: React.ReactNode;
  content?: React.ReactNode;
  persistent?: boolean;
  timeout?: number;
};

type ProviderValue = {
  dispatchNotification: (type: NotificationType, data: NotificationEmitData) => any;
  deleteNotification: (id: string) => any;
  notifications: Array<NotificationObjectType>;
  emitNotification: {
    success: (data: NotificationEmitData) => any;
    warning: (data: NotificationEmitData) => any;
    error: (data: NotificationEmitData) => any;
    info: (data: NotificationEmitData) => any;
  };
};

let [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

function notificationReducer(prevState, action) {
  let prevStateClone = [...(prevState || [])];
  switch (action.action) {
    case 'remove':
      let index = prevStateClone.findIndex((notification) => notification.id === action.id);
      if (index > -1) {
        prevStateClone.splice(index, 1);
      }
      break;
    case 'add':
      prevStateClone.push(action.value);
      break;
  }
  return prevStateClone;
}

function useNotification() {
  // @ts-ignore
  let [notifications, dispatch] = React.useReducer(notificationReducer, []);

  const dispatchNotification = (type: string, data: NotificationEmitData) => {
    dispatch({
      action: 'add',
      value: {
        id: uuid(),
        type: type,
        title: data.title,
        content: data.content,
        persistent: !!data.persistent,
        timeout: data.timeout || 5000,
        createdAt: Date.now(),
      },
    });
  };

  const deleteNotification = (id: string) => {
    dispatch({
      action: 'remove',
      id,
    });
  };

  return {
    deleteNotification,
    dispatchNotification,
    notifications,
    emitNotification: {
      success: (data) => dispatchNotification('success', data),
      warning: (data) => dispatchNotification('warning', data),
      error: (data) => dispatchNotification('error', data),
      info: (data) => dispatchNotification('info', data),
    },
  };
}

export function NotificationProvider(props: ProviderProps) {
  let { children } = props;

  return <ReactProvider value={useNotification()}>{children}</ReactProvider>;
}

export const useNotificationContext = useContext;
export const Consumer = ReactConsumer;
