import { TranslatorProvider } from '@translator-app/react';
import * as React from 'react';

import { TRANSLATIONS as de } from '../../i18n/de';
import { TRANSLATIONS as en } from '../../i18n/en';
import { TRANSLATIONS as fr } from '../../i18n/fr';
import { TRANSLATIONS as nl } from '../../i18n/nl';
import { setDateLocale } from '../utils/date';

interface ProviderProps {
  children: React.ReactNode;
  initialLanguage: string;
}

interface ProviderValue {
  language: string;
  setLanguage: (newLang: string) => void;
}

const languageContext = React.createContext<ProviderValue>(null);

export function LanguageProvider(props: ProviderProps) {
  let { children, initialLanguage } = props;
  let [language, setLanguage] = React.useState(initialLanguage);

  React.useEffect(() => {
    setDateLocale(language);
  }, [language]);

  return (
    <languageContext.Provider value={{ language, setLanguage }}>
      <TranslatorProvider language={language} fallbackLanguage="en" library={{ nl, en, fr, de }}>
        {children}
      </TranslatorProvider>
    </languageContext.Provider>
  );
}

export const useLanguageContext = () => {
  return React.useContext<ProviderValue>(languageContext);
};
