import { useEffect } from 'react';
import { OperationContext, UseQueryState } from 'urql';

export function useUrqlPolling(
  result: UseQueryState<any, any>,
  refetch: (opts?: Partial<OperationContext>) => void,
  pollInterval: number
) {
  useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(() => refetch({ requestPolicy: 'network-only' }), 5000);
      return () => clearTimeout(id);
    }
  }, [result.fetching, refetch, pollInterval]);
}
