import { useRouter } from 'next/router';
import React from 'react';
import { Client, Provider } from 'urql';

import { createGraphqlClient } from '../utils/graphql/client';
import { isValidObjectId } from '../utils/object-id';
import { useApplicationContext } from './application-context';

interface Props {}

interface ContextValue {
  organisationHeader: string;
}

const Context = React.createContext<ContextValue>({
  organisationHeader: null,
});

export const GraphQLProvider: React.FC<Props> = (props) => {
  let { children } = props;
  let router = useRouter();
  let { userId } = useApplicationContext();
  let organisationId = Array.isArray(router.query?.organisationId)
    ? router.query?.organisationId.join('')
    : router.query?.organisationId || '';

  // Redirect to root if this is invalid organisation id
  React.useEffect(() => {
    if (organisationId && !isValidObjectId(organisationId)) {
      router.push('/');
    }
  }, [organisationId]);

  const makeGraphQLClient = () => {
    let organisationHeader = organisationId && isValidObjectId(organisationId) ? organisationId : '';

    return {
      client: createGraphqlClient({
        organisationId: organisationHeader,
      }),
      organisationHeader: organisationHeader,
    };
  };

  let [{ client, organisationHeader }, setClient] =
    React.useState<{ client: Client; organisationHeader: string }>(makeGraphQLClient);

  React.useEffect(() => {
    setClient(makeGraphQLClient());
  }, [userId, organisationId]);

  const ctxValue = {
    organisationHeader,
  };

  return (
    <Context.Provider value={ctxValue}>
      <Provider value={client}>{children}</Provider>
    </Context.Provider>
  );
};

export const useGraphqlClient = () => React.useContext(Context);
