import classNames from 'classnames';
import React from 'react';

export type Props = {
  size?: 4 | 6 | 10 | 16;
  color?: string;
};

export default function Spinner(props: Props) {
  let { size = 4, color = 'gray-800' } = props;

  return (
    <div
      className={classNames('inline-block relative', {
        'w-4 h-4': size === 4,
        'w-6 h-6': size === 6,
        'w-10 h-10': size === 10,
        'w-16 h-16': size === 16,
      })}
      style={{ pointerEvents: 'none' }}
    >
      <div
        className={classNames('absolute block w-full h-full top-0 left-0 rounded-full', `border-${color}`, {
          'border-2': size === 4 || size === 6,
          'border-4': size === 10,
          'border-8': size === 16,
        })}
        style={{
          borderRightColor: 'transparent',
          borderTopColor: 'transparent',
          animation: 'spinning-animation 500ms infinite linear',
        }}
      ></div>
      <div className="visually-hidden">loading...</div>
    </div>
  );
}
