import PageSpinner from '~/components/page-spinner';
import React from 'react';

import { CurrentOrganisationQuery } from '../generated/graphql';
import { getAccessToken } from '../utils/auth/client';
import { ShopType } from './shop-context';

export type CurrentOrganisation = CurrentOrganisationQuery['getCurrentOrganisation'];

interface Props {}

interface ContextValue {
  userId?: string | null;
  setUserId: (newUserId: string) => void;
  organisation?: CurrentOrganisation;
  setOrganisation?: (newOrganisation: CurrentOrganisation) => void;
  shop?: ShopType;
  setShop?: (newShop: ShopType) => void;
}

const Context = React.createContext<ContextValue>({
  userId: null,
  setUserId: () => {},
});

const useAuthentication = () => {
  let [tokenData, setTokenData] = React.useState(null);
  let [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getAccessToken()
      .then((tokenData) => {
        setTokenData(tokenData);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [true]);

  return { tokenData, isLoading };
};

export const ApplicationContextProvider: React.FC<Props> = (props) => {
  let { children } = props;
  let { tokenData } = useAuthentication();
  let [userId, setUserId] = React.useState<string | null>(null);
  let [organisation, setOrganisation] = React.useState<CurrentOrganisation | null>(null);
  let [shop, setShop] = React.useState<ShopType | null>(null);

  React.useEffect(() => {
    if (tokenData) {
      setUserId(tokenData?.decoded?.sub);
    }
  }, [tokenData]);

  if (!tokenData) {
    return <PageSpinner />;
  }

  const ctxValue = {
    userId,
    setUserId,
    organisation,
    setOrganisation,
    shop,
    setShop,
  };

  return <Context.Provider value={ctxValue}>{children}</Context.Provider>;
};

export const useApplicationContext = () => React.useContext(Context);
