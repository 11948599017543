import { useTranslator } from '@translator-app/react';
import * as React from 'react';

import { useServerNotificationsQuery } from '../generated/graphql';
import useLocalstorage from '../hooks/use-localstorage';
import { useUrqlPolling } from '../hooks/use-urql-polling';
import { useWindowVisible } from '../hooks/use-window-visible';
import createContext from './create-context';
import { useNotificationContext } from './notification-context';

const NOTIFICATION_POLL_INTERVAL = 10_000;

type ProviderProps = {
  children: React.ReactNode;
};

type ProviderValue = {
  notificationCount: number;
  lastNotificationId?: string | null;
};

let [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

export function ServerNotificationsProvider(props: ProviderProps) {
  const { children } = props;
  const [lastNotificationId, setLastNotificationId] = useLocalstorage('lastNotificationDate');
  const notification = useNotificationContext();
  const { translate } = useTranslator();
  const isWindowVisible = useWindowVisible();
  const [notificationsResult, refetchNotifications] = useServerNotificationsQuery({
    pause: !isWindowVisible,
    requestPolicy: 'cache-and-network',
    variables: {
      pagination: {
        skip: 0,
        limit: 1,
      },
    },
  });

  useUrqlPolling(notificationsResult, refetchNotifications, NOTIFICATION_POLL_INTERVAL);

  const { data } = notificationsResult;
  const newestNotificationId = data?.notifications?.docs[0]?._id;
  React.useEffect(() => {
    if (newestNotificationId && newestNotificationId !== lastNotificationId) {
      notification.emitNotification.info({
        title: translate('newNotifications'),
      });

      setLastNotificationId(newestNotificationId);
    }
  }, [newestNotificationId, lastNotificationId]);

  return (
    <ReactProvider
      value={{
        notificationCount: data?.notifications?.total || 0,
        lastNotificationId,
      }}
    >
      {children}
    </ReactProvider>
  );
}

export const useServerNotificationProvider = useContext;
export const Consumer = ReactConsumer;
