import { UrlObject } from 'url';

import classNames from 'classnames';
import NextLink from 'next/link';
import * as React from 'react';

import Spinner from './spinner';

export type Props = {
  children: React.ReactNode;
  onPress?: (evt?: React.MouseEvent<HTMLButtonElement>) => any;
  appearance?: 'default' | 'primary' | 'success' | 'warning' | 'danger' | 'subtle';
  isDisabled?: boolean;
  isIcon?: boolean;
  isLoading?: boolean;
  isSelected?: boolean;
  href?: string | UrlObject;
  as?: string | UrlObject;
  testId?: string;
  isFullSize?: boolean;
};

interface ImplementationProps extends Props {
  className?: string;
}

const BasicButtonImpl: React.FC<ImplementationProps> = (props) => {
  let { onPress, isDisabled = false, isLoading = false, className, children, testId } = props;

  return (
    <button
      onClick={(evt) => {
        onPress(evt);
      }}
      onKeyDown={(evt) => {
        if (evt?.key?.toLowerCase() === 'enter') {
          onPress();
        }
      }}
      type="button"
      className={className}
      disabled={isDisabled || isLoading}
      test-id={testId}
    >
      {children}
    </button>
  );
};

const LinkButtonImpl: React.FC<ImplementationProps> = (props) => {
  let { href, isDisabled = false, as, className, children, testId } = props;

  if (isDisabled) {
    return <div className={className}>{children}</div>;
  } else {
    return (
      <NextLink href={href} as={as} passHref>
        <a className={className} test-id={testId}>
          {children}
        </a>
      </NextLink>
    );
  }
};

export const Button: React.FC<Props> = (props) => {
  let {
    children,
    isDisabled = false,
    appearance = 'default',
    isIcon = false,
    isLoading = false,
    isSelected = false,
    href,
    isFullSize,
  } = props;

  isSelected = isSelected && !isDisabled;
  let isDisabledStyle = isDisabled || isLoading || isSelected;

  let styling = classNames('block rounded font-medium border focus:outline-none whitespace-nowrap', {
    'block w-full': isFullSize,
    relative: !isFullSize,
    'cursor-pointer focus:ring': !isDisabledStyle,
    'bg-gray-100 text-gray-500 border-transparent': isDisabled,
    'cursor-default': isDisabledStyle,
    'p-2': !!isIcon,
    'px-4 py-1': !isIcon,
    shadow: appearance !== 'subtle',
    'bg-white text-gray-800 border-gray-300': appearance === 'default' && !isDisabled,
    'hover:bg-gray-200': (appearance === 'subtle' || appearance === 'default') && !isDisabledStyle,
    'bg-gray-300': (appearance === 'subtle' || appearance === 'default') && isSelected,
    'bg-transparent text-gray-800 border-transparent': appearance === 'subtle' && !isDisabled,
    'bg-blue-500 text-white border-transparent': appearance === 'primary' && !isDisabled,
    'hover:bg-blue-600': appearance === 'primary' && !isDisabledStyle,
    'bg-blue-700': appearance === 'primary' && isSelected,
    'bg-green-500 text-white border-transparent': appearance === 'success' && !isDisabled,
    'hover:bg-green-600': appearance === 'success' && !isDisabledStyle,
    'bg-green-700': appearance === 'success' && isSelected,
    'bg-yellow-500 text-white border-transparent': appearance === 'warning' && !isDisabled,
    'hover:bg-yellow-600': appearance === 'warning' && !isDisabledStyle,
    'bg-yellow-700': appearance === 'warning' && isSelected,
    'bg-red-500 text-white border-transparent': appearance === 'danger' && !isDisabled,
    'hover:bg-red-600': appearance === 'danger' && !isDisabledStyle,
    'bg-red-700': appearance === 'danger' && isSelected,
  });

  let buttonContent = (
    <React.Fragment>
      <div
        className={classNames('flex items-center justify-center h-full w-full', {
          'opacity-0': isLoading,
        })}
      >
        {children}
      </div>
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Spinner
            color={['default', 'subtle'].includes(appearance) ? (isDisabled ? 'gray-500' : 'gray-800') : 'white'}
          />
        </div>
      )}
    </React.Fragment>
  );

  if (href) {
    return (
      <LinkButtonImpl {...props} className={styling}>
        {buttonContent}
      </LinkButtonImpl>
    );
  } else {
    return (
      <BasicButtonImpl {...props} className={styling}>
        {buttonContent}
      </BasicButtonImpl>
    );
  }
};
