import { useTranslator } from '@translator-app/react';
import useKeybind from '~/hooks/use-keybind';
import { useScreenSize } from '~/hooks/use-screen-size';
import React from 'react';
import ReactDOM from 'react-dom';

import { Button } from './button';

export type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  onClose: () => any;
  show?: boolean;
  hideCloseButton?: boolean;
};

export default function Popup(props: Props) {
  let { children, onClose, show, hideCloseButton } = props;
  let { translate } = useTranslator();

  useKeybind(['escape'], onClose);
  let screenSize = useScreenSize();
  let isLarge = screenSize.width > 768;

  if (!show) {
    return null;
  }

  let content = (
    <div className="w-full relative">
      {!hideCloseButton && (
        <div className="flex justify-end md:absolute m-4 md:m-0" style={{ right: 0, top: '-3rem' }}>
          <Button appearance="danger" onPress={onClose}>
            {translate('close')}
          </Button>
        </div>
      )}
      <div className="w-full py-8 px-4">{children}</div>
    </div>
  );

  let result = null;
  if (isLarge) {
    result = (
      <div
        className="w-full fixed z-50 top-0 left-0 min-h-screen h-full px-12 overflow-y-auto"
        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        onClick={onClose}
      >
        <div
          className="bg-white lg:max-w-6xl relative shadow mx-auto h-auto rounded my-24"
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
          }}
        >
          {content}
        </div>
      </div>
    );
  } else {
    result = (
      <div className="bg-white lg:max-w-6xl overflow-y-auto w-full min-h-screen h-full pb-48 fixed z-50 top-0 left-0 overscroll-none">
        {content}
      </div>
    );
  }

  return ReactDOM.createPortal(result, document.getElementById('__next'));
}
