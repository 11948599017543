import '../styles/style.css';

import { GlobalErrorBoundary } from '~/app/error/global-error-boundary';
import PageSpinner from '~/components/page-spinner';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React, { Suspense } from 'react';

import { BUILD_ID } from '../constants';
import { ApplicationContextProvider } from '../contexts/application-context';
import { GraphQLProvider } from '../contexts/graphql-context';
import { LanguageProvider } from '../contexts/language-context';
import { NotificationProvider } from '../contexts/notification-context';
import { ServerNotificationsProvider } from '../contexts/server-notifications-context';
import { UserProvider } from '../contexts/user-context';
import RouterLoading from '../templates/router-loading';

if (typeof window !== 'undefined') {
  let initialized = Date.now();
  let lastActivity = Date.now();
  const updateLastActivity = () => {
    lastActivity = Date.now();
  };

  document.addEventListener('mousemove', updateLastActivity);
  document.addEventListener('mousedown', updateLastActivity);
  document.addEventListener('keypress', updateLastActivity);
  document.addEventListener('DOMMouseScroll', updateLastActivity);
  document.addEventListener('mousewheel', updateLastActivity);
  document.addEventListener('touchmove', updateLastActivity);

  // 5 minutes
  const UPDATE_CHECK_INTERVAL = 300000;

  // Always reload after a day of no reloading
  const MAX_PAGE_TIME = 86400 * 1000;
  window.setInterval(async () => {
    // Skip if user is typing as we don't want to interrupt their work
    if (lastActivity + UPDATE_CHECK_INTERVAL > Date.now()) {
      console.log('Skip update check, application is not idle');
      return;
    }

    // Page has been open for too long
    if (initialized + MAX_PAGE_TIME < Date.now()) {
      window.location.reload();
      return;
    }

    let buildManifestUrl = `/_next/static/${BUILD_ID}/_buildManifest.js`;
    let response = await fetch(buildManifestUrl);
    if (!response.ok) {
      console.log('Reloading to get the latest version');
      window.location.reload();
    } else {
      console.log('Running the latest version');
    }
  }, UPDATE_CHECK_INTERVAL);
}

// Workaround for https://github.com/vercel/next.js/issues/8592
export default function App({ Component, pageProps, router }: AppProps) {
  // Opting out of SSR in a way
  if (typeof window === 'undefined') {
    return null;
  }

  console.log(router.pathname);
  let content = null;
  if (router.pathname.startsWith('/auth/')) {
    // console.log('Loaded an auth route');
    content = <Component {...pageProps} />;
  } else {
    content = (
      <ApplicationContextProvider>
        <GraphQLProvider>
          <UserProvider>
            <ServerNotificationsProvider>
              <Component {...pageProps} />
            </ServerNotificationsProvider>
          </UserProvider>
        </GraphQLProvider>
      </ApplicationContextProvider>
    );
  }

  return (
    <GlobalErrorBoundary>
      <Head>
        <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1" />
      </Head>

      <Suspense fallback={<PageSpinner />}>
        <RouterLoading>
          <LanguageProvider initialLanguage="en">
            <NotificationProvider>{content}</NotificationProvider>
          </LanguageProvider>
        </RouterLoading>
      </Suspense>
    </GlobalErrorBoundary>
  );
}

export { captureException } from '../utils/sentry';
