import useDelayedShow from '~/hooks/use-delayed-show';
import React from 'react';

import { Button } from './button';
import Spinner from './spinner';

export default function PageSpinner() {
  let showSpinner = useDelayedShow(250);
  let showTookToLongMessage = useDelayedShow(10000);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col justify-center items-center">
        {showSpinner && (
          <div>
            <Spinner size={10} />
          </div>
        )}
        {showTookToLongMessage && (
          <div className="my-8">
            <p>Loading is taking longer than expected, something probably went wrong</p>
            <div className="flex justify-center items-center mt-4">
              <Button href="/" appearance="primary">
                Go to overview
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
