import { useTranslator } from '@translator-app/react';
import { Button } from '~/components/button';
import PageSpinner from '~/components/page-spinner';
import Popup from '~/components/popup';
import * as React from 'react';

import { AuthRolesEnum } from '../enums';
import {
  CurrentUserQuery,
  useCurrentUserQuery,
  useGetAnnouncementByIdQuery,
  useHideAnnouncementMutation,
} from '../generated/graphql';
import { formatDateTime } from '../utils/date';
import { setSentryUser } from '../utils/sentry';
import createContext from './create-context';
import { useLanguageContext } from './language-context';

export type CurrentUserType = NonNullable<CurrentUserQuery['getCurrentUser']>;

type ProviderProps = {
  children: React.ReactNode;
};

type ProviderValue = {
  currentUser: CurrentUserType;
  highestRole: number;
};

let [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface AnnouncementProps {
  id: string;
  onClose: () => void;
}

const Announcement: React.FC<AnnouncementProps> = (props) => {
  const { id, onClose } = props;
  const { translate } = useTranslator();
  let [{ data }] = useGetAnnouncementByIdQuery({
    variables: {
      id,
    },
    pause: !id,
  });

  const announcement = data?.getAnnouncementById;
  if (!announcement) {
    return null;
  }

  return (
    <Popup onClose={onClose} show={true}>
      <div className="text-gray-800 text-xl font-bold mb-1">{announcement.title}</div>
      <div className="text-gray-600 font-medium text-sm mb-2">{formatDateTime(announcement.createdAt)}</div>
      <div className="overflow-y-auto whitespace-pre-line">{announcement.content}</div>
      <div className="flex justify-end mt-4">
        <Button onPress={onClose} appearance="primary">
          {translate('confirm')}
        </Button>
      </div>
    </Popup>
  );
};

export function UserProvider(props: ProviderProps) {
  const { children } = props;
  const { setLanguage } = useLanguageContext();
  const [{ data }] = useCurrentUserQuery({});
  const [, hideAnnouncementMut] = useHideAnnouncementMutation();

  const currentUser = data?.getCurrentUser;

  React.useEffect(() => {
    if (currentUser) {
      setSentryUser(currentUser);
      setLanguage(currentUser.language);
    }
  }, [currentUser]);

  const announcementToShow = React.useMemo(() => {
    if (data?.getCurrentUser && data?.announcements?.docs?.length) {
      const lastReadAnnouncementAt = new Date(data.getCurrentUser.lastReadAnnouncementAt).getTime() || 0;
      const newestAnnouncement = data.announcements.docs[0];
      if (new Date(newestAnnouncement.createdAt).getTime() > lastReadAnnouncementAt) {
        return newestAnnouncement;
      }
    }
  }, [data]);

  if (!currentUser) {
    return <PageSpinner />;
  }

  const hideAnnouncement = async () => {
    try {
      await hideAnnouncementMut({});
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ReactProvider
      value={{
        currentUser,
        highestRole: currentUser.highestRole?.role || AuthRolesEnum.Member,
      }}
    >
      {!!announcementToShow && <Announcement id={announcementToShow._id} onClose={hideAnnouncement} />}
      {children}
    </ReactProvider>
  );
}

export const useUserContext = useContext;
export const Consumer = ReactConsumer;
